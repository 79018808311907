<template lang='pug'>
	.pay-services
		a(href='https://www.visa.com' target='_blank')
			img.pay-services-img(src='/assets/img/icons/visa.svg')
		a(href='https://www.mastercard.com' target='_blank')
			img.pay-services-img(src='/assets/img/icons/mastercard.svg')
		a(href='https://mironline.ru' target='_blank')
			img.pay-services-img.mr-0(src='/assets/img/icons/mir.svg')
</template>

<style scoped lang='scss'>
	.pay-services {
		display: inline-flex;
		align-items: center;

		.pay-services-img {
			height: 15px;
			margin-right: 5px;
		}
	}

	@media (max-width: 767.98px) {
		.pay-services {
			a { line-height: 7px; }
			.pay-services-img {
				height: 7px;
				margin-right: 4px;
			}
		}
	}
</style>

<script>

export default {
	name: 'PayServices',

	data: () => ({
		
	}),

	components: {
		
	},

	created () {
		
	},
	
	methods: {

	},

	computed: {
		
	}
}
</script>